<template> 
 <svg
    class="cursor-pointer"
    :class="{
        'rotate-up': props.direction === 'up',
        'rotate-right': props.direction === 'right',
        'rotate-left': props.direction === 'left'
    }"
    @click="handleClick"
    width="16" height="9" viewBox="0 0 16 9" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
<path 
    d="M15.6601 1.89025L8.75056 8.39524C8.54146 8.63162 8.26871 8.73163 8.00051 8.73163C7.73231 8.73163 7.46047 8.63217 7.25046 8.43334L0.340887 1.89025C-0.0968708 1.47203 -0.115054 0.781078 0.298157 0.344683C0.713186 -0.0962562 1.40642 -0.109893 1.84099 0.303772L8.00051 6.13599L14.16 0.299226C14.5947 -0.114167 15.2851 -0.0985291 15.7029 0.341842C16.1147 0.781078 16.0965 1.47203 15.6601 1.89025Z" 
    fill="#393C3F"/>
</svg>
</template>

<script setup lang="ts">
const emit = defineEmits(['click']);

const props = withDefaults(defineProps<{
    direction?: 'up' | 'down' | 'left' | 'right';
}>(), {
    direction: 'down'
});

const handleClick = () => {
    emit('click');
};
</script>

<style>
.rotate-up {
    transform: rotate(180deg);
}

.rotate-right {
    transform: rotate(-90deg);
}

.rotate-left {
    transform: rotate(90deg);
}
</style>