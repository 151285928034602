import {get as cookieGet, set as cookieSet, remove as cookieRemove, getAll as cookieGetAll} from 'tiny-cookie';

declare interface CookieOptions {
  domain?: string;
  path?: string;
  expires?: Date | string | number;
  "max-age"?: number;
  secure?: boolean;
  samesite?: string;
}

interface Cookies<T = string> {
  [key: string] : T
}

interface VueCookie {
  get<T = string>(name: string) : T | null;
  set<T = string>(key: string, value: string, options?: CookieOptions) : void;
  remove(key: string, options?: CookieOptions) : void;

  delete(key: string, options?: CookieOptions) : void;
  getAll<T = string>() : Cookies<T>;
}

const vueCookie: VueCookie = {
  // Main functions
  get: (name) => cookieGet(name),
  set: (name, value, options) => cookieSet(name, value, options),
  remove: (name, options) => cookieRemove(name, options),

  // More functions
  delete: (name, options) => cookieRemove(name, options), // alias of remove
  getAll: () => cookieGetAll()
}

export default (app: any) => {
  app.config.globalProperties.$cookie = vueCookie
};
