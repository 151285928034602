import { createStore } from "vuex";
import { mainInitialState, mainInterfaceGetters, mainInterfaceMutations, mainInterfaceActions } from "@/store/mainInterface";
import { aanvragerInitialState, aanvragerInterfaceGetters, aanvragerInterfaceMutations } from "@/store/aanvragerInterface";
import { flowInitialState, flowInterfaceGetters, flowInterfaceMutations, flowInterfaceActions } from "@/store/flowInterface";
import { questionnaireInitialState, questionnaireInterfaceMutations } from "@/store/questionnaireInterface";

const initialState = {
  ...mainInitialState,
  ...aanvragerInitialState,
  ...flowInitialState,
  ...questionnaireInitialState,
};

export type StoreState = typeof initialState;

export default createStore<StoreState>({
  state: structuredClone(initialState),
  getters: {
    ...mainInterfaceGetters,
    ...aanvragerInterfaceGetters,
    ...flowInterfaceGetters,
    ...aanvragerInterfaceGetters,
  },
  mutations: {
    initialiseStore(state) {
      const storedState = localStorage.getItem('store');

      if(storedState) {
        Object.assign(state, JSON.parse(storedState));
      }
    },
    resetState(state) {
      Object.assign(state, structuredClone(initialState));
    },
    ...mainInterfaceMutations,
    ...aanvragerInterfaceMutations,
    ...flowInterfaceMutations,
    ...questionnaireInterfaceMutations,
  },
  actions: {
    async initialiseStore(context) {
      context.commit('initialiseStore');

      // Not awaited because a page reload takes about 5x longer if awaited
      context.dispatch('checkAuth');
    },
    ...mainInterfaceActions,
    ...flowInterfaceActions,
  },
  modules: {},
});
