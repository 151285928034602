/* TYPES HAVE TO BE RE DONE HERE */
import request, { requestMethodType } from '@/plugins/request'
import store, { StoreState } from '@/store'
import type { IdentityDocumentType } from '@/types/common';
import type { GetterTree, MutationTree } from 'vuex';

export type AanvragerType = 'mijn' | 'partner' | 'child';
export type KopieType = 'uploaden' | 'post';

export type Document = {
  name: string;
  content: string;
};

export type AanvragerDocuments = {
  voorkant: Document;
  achterkant: Document;
};

/* Everyone has these properties */
export type AbroadTaxInfo = {
  taxNL: string;
  taxAbroad: string;
  country: string | null;
  taxIdentificationNumber: string | null;
  taxUS: string;
  bornUS: string | null;
  taxIdentificationNumberUS: string | null;
  copyPassport: KopieType | null;
  copyCLN: KopieType | null;
  passport: IdentityDocumentType;
  CLN: Document | null;
};

// Everyone has these properties (set on fiscaal pages)
type FiscaalData = {
  bsn: string | null;
  documents: AanvragerDocuments | null;
  geldigTot: string | null;
  identiteitsbewijs: string | null;
  identiteitsbewijsCard: string | null;
  kopie: KopieType | null;
  uitsluitend: string | null;
  abroadTax: AbroadTaxInfo | null;
};

// Everyone has these properties (set on aanvragen pages)
type ChildAanvragenData = {
  achternaam: string | null;
  geboortedatum: string | null;
  geslacht: string | null;
  initialen: string | null;
  tussenvoegsel: string | null;
};

// Partner has all child properties plus these extra properties
type PartnerAanvragenData = ChildAanvragenData & {
  huisnummer: string | null;
  land: string | null;
  mobiel: string | null;
  nationaliteit: string | null;
  postcode: string | null;
  straatnaam: string | null;
  telefoonnummer: string | null;
  woonplaats: string | null;
};

// Mijn has all partner properties plus these extra properties
type MijzelfAanvragenData = PartnerAanvragenData & {
  iban: string | null;
};

type ChildData = ChildAanvragenData & FiscaalData;
type PartnerData = PartnerAanvragenData & FiscaalData;
type MijnData = MijzelfAanvragenData & FiscaalData;

type AanvragerData = {
  aanvragen: {
    child: ChildData;
    partner: PartnerData;
    mijn: MijnData;
    selectedRelatie: number | null;
  }
};

const abroadTaxInitialState: AbroadTaxInfo = {
  taxNL: '',
  taxAbroad: '',
  country: null,
  taxIdentificationNumber: null,
  taxUS: '',
  bornUS: null,
  taxIdentificationNumberUS: null,
  copyPassport: null,
  copyCLN: null,
  passport: { voorkant: null, achterkant: null },
  CLN: null,
};

export const aanvragerInitialState: AanvragerData = {
  aanvragen: {
    child: {
      achternaam: null,
      bsn: null,
      documents: null,
      geboortedatum: null,
      geldigTot: null,
      geslacht: null,
      identiteitsbewijs: null,
      identiteitsbewijsCard: null,
      initialen: null,
      kopie: null,
      uitsluitend: null,
      tussenvoegsel: null,
      abroadTax: structuredClone(abroadTaxInitialState),
    },
    mijn: {
      achternaam: null,
      bsn: null,
      documents: null,
      geboortedatum: null,
      geldigTot: null,
      geslacht: null,
      huisnummer: null,
      iban: null,
      identiteitsbewijs: null,
      identiteitsbewijsCard: null,
      initialen: null,
      kopie: null,
      land: null,
      mobiel: null,
      telefoonnummer: null,
      nationaliteit: null,
      postcode: null,
      straatnaam: null,
      tussenvoegsel: null,
      uitsluitend: null,
      woonplaats: null,
      abroadTax: structuredClone(abroadTaxInitialState),
    },
    partner: {
      achternaam: null,
      bsn: null,
      documents: null,
      geboortedatum: null,
      geldigTot: null,
      geslacht: null,
      huisnummer: null,
      identiteitsbewijs: null,
      identiteitsbewijsCard: null,
      initialen: null,
      kopie: null,
      land: null,
      mobiel: null,
      telefoonnummer: null,
      nationaliteit: null,
      postcode: null,
      straatnaam: null,
      tussenvoegsel: null,
      uitsluitend: null,
      woonplaats: null,
      abroadTax: structuredClone(abroadTaxInitialState),
    },
    selectedRelatie: null,
  },
} 

export const aanvragerInterfaceGetters: GetterTree<StoreState, StoreState> = {
  aanvragerData: (state): AanvragerData => state.aanvragen.aanvrager,
  childData: (state): ChildData => state.aanvragen.child,
  partnerData: (state): PartnerData => state.aanvragen.partner,
  mijnData: (state): MijnData => state.aanvragen.mijn,
  fiscaalData: (state) => (aanvrager: AanvragerType): FiscaalData => {
    const aanvragenData = state.aanvragen[aanvrager];
    return {
      bsn: aanvragenData.bsn,
      documents: aanvragenData.documents,
      geldigTot: aanvragenData.geldigTot,
      identiteitsbewijs: aanvragenData.identiteitsbewijs,
      identiteitsbewijsCard: aanvragenData.identiteitsbewijsCard,
      kopie: aanvragenData.kopie,
      uitsluitend: aanvragenData.uitsluitend,
      abroadTax: aanvragenData.abroadTax,
    };
  },
  selectedRelatie: (state): number => state.aanvragen.selectedRelatie,
}

export const aanvragerInterfaceMutations: MutationTree<StoreState> = {
  resetAanvragerData: (state): void => {
    Object.assign(state.aanvragen, structuredClone(aanvragerInitialState.aanvragen))
  },
  resetMijzelfAanvragenData: (state): void => {
    Object.assign(state.aanvragen.mijn, structuredClone(aanvragerInitialState.aanvragen.mijn))
  },
  resetChildAanvragenData: (state): void => {
    Object.assign(state.aanvragen.child, structuredClone(aanvragerInitialState.aanvragen.child))
  },
  resetPartnerAanvragenData: (state): void => {
    Object.assign(state.aanvragen.partner, structuredClone(aanvragerInitialState.aanvragen.partner))
  },
  setChildAanvragenData: (state, payload: ChildAanvragenData): void => {
    Object.assign(state.aanvragen.child, payload)
  },
  setPartnerAanvragenData: (state, payload: PartnerAanvragenData): void => {
    Object.assign(state.aanvragen.partner, payload)
  },
  setMijzelfAanvragenData: (state, payload: MijzelfAanvragenData): void => {
    Object.assign(state.aanvragen.mijn, payload)
  },
  setFiscaalData: (state, payload: { aanvrager: AanvragerType, data: Partial<FiscaalData> }): void => {
    Object.assign(state.aanvragen[payload.aanvrager], payload.data)
  },
  setSelectedRelatie: (state, payload: number): void => {
    state.aanvragen.selectedRelatie = payload
  },
  setAanvragen(state, payload: AanvragerData) {
    state.aanvragen = {
      ...state.aanvragen,
      ...payload,
    }
  },
}

export const getChildData = (): ChildData => store.getters.childData
export const getPartnerData = (): PartnerData => store.getters.partnerData
export const getMijnData = (): MijnData => store.getters.mijnData
export const getFiscaalData = (aanvrager: AanvragerType): FiscaalData => store.getters.fiscaalData(aanvrager)

export const getSelectedRelatie = (): number => store.getters.selectedRelatie

export const setFiscaalData = (aanvrager: AanvragerType, data: Partial<FiscaalData>): void => store.commit('setFiscaalData', { aanvrager, data })

export const setChildAanvragenData = (data: ChildAanvragenData): void => store.commit('setChildAanvragenData', data)
export const setPartnerAanvragenData = (data: PartnerAanvragenData): void => store.commit('setPartnerAanvragenData', data)
export const setMijzelfAanvragenData = (data: MijzelfAanvragenData): void => store.commit('setMijzelfAanvragenData', data)
export const setSelectedRelatie = (relatie: number): void => store.commit('setSelectedRelatie', relatie)

export const resetAanvragerData = (): void => store.commit('resetAanvragerData')
export const resetMijzelfAanvragenData = (): void => store.commit('resetMijzelfAanvragenData')
export const resetPartnerAanvragenData = (): void => store.commit('resetPartnerAanvragenData')
export const resetChildAanvragenData = (): void => store.commit('resetChildAanvragenData')

export const getAddress = async (postcode: string, houseNumber: string) => {
  const payload = {
    postcode: postcode.replace(/\s/g, ''),
    houseNumber: parseInt(houseNumber),  /* get house-number and stop parsing after first non-numeric character */
  }

  let response = null
  try {
    response = await request('/api/postcode', requestMethodType.POST, payload, { headers: {}, auth: true })
  } catch (e) {
    console.error(e)
  }
  return response
}