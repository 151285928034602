import { clearStore, getStore } from '@/db';

type File = {
  path: string,
  filename: string,
  content: string,
};

const config = {
  storeName: 'files',
};

export async function putFile(file: File) {
  const store = await getStore(config.storeName, 'readwrite');
  const request = store.put(file);

  return new Promise<void>(resolve => {
    request.onsuccess = event => resolve();
  });
}

export async function getFile(path: string) {
  const store = await getStore(config.storeName, 'readonly');
  const request = store.get(path);

  return new Promise<File>(resolve => {
    request.onsuccess = event => {
      if (!(event.target instanceof IDBRequest)) return;
      resolve(event.target.result);
    };
  });
}

export async function deleteFile(path: string) {
  const store = await getStore(config.storeName, 'readwrite');
  const request = store.delete(path);

  return new Promise<void>(resolve => {
    request.onsuccess = event => resolve();
  });
}

export function createFileStore(db: IDBDatabase) {
  db.createObjectStore(config.storeName, { keyPath: 'path' });
}

export async function clearFileStore() {
  await clearStore(config.storeName);
}
