<template>
  <div class="overlay">
    <div :class="['modal', { wide: !props.isMaxLg }]">
      <div v-if="props.showClose || props.title" class="header">
        <div v-if="props.title" class="title">{{ props.title }}</div>

        <button v-if="props.showClose" class="button-close" @click="$emit('close')">
          <svg class="icon-close" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>

        <slot />

      <div v-if="!props.hideFooter && (props.confirmButton || props.cancelButton)" class="footer">
        <Button type="primary" v-if="props.confirmButton" @click="$emit('confirm')">{{ props.confirmButton }}</Button>
        <Button type="secondary" v-if="props.cancelButton" @click="$emit('close')">{{ props.cancelButton }}</Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from "@/components/Button.vue";
import {onBeforeUnmount, onMounted} from "vue";

const emits = defineEmits<{
  'close': [];
  'confirm': [];
}>();

const props = defineProps<{
  cancelButton?: string;
  confirmButton?: string;
  hideFooter?: boolean;
  isMaxLg?: boolean;
  showClose?: boolean;
  title?: string;
}>();

onMounted(() => window.addEventListener('keydown', keydownHandler));
onBeforeUnmount(() => window.removeEventListener('keydown', keydownHandler));

const keydownHandler = (event: Event) => {
  if(!(event instanceof KeyboardEvent && event.key === 'Escape')) return;
  emits('close');
}

</script>

<style scoped lang="scss">
@import "@env_styles/variables";

.overlay {
  @apply top-4 md:inset-0;

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 50;
  margin: 0 !important;
  overflow: hidden auto;
  background-color: rgba(94, 101, 108, 0.2);
}

.modal {
  @apply shadow h-full md:h-auto;

  position: relative;
  width: 28rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;

  @media (max-width: 768px) {
        width: 25rem;
        height: fit-content;
    }

  &.wide {
    max-width: 42rem;
  }

  > * + * {
    margin-top: 1.5rem;
  }
}

.header {
  @apply dark:border-gray-600;
  display: flex;

  .title {
    @apply text-gray-900;

    margin: 0 auto;
    font-weight: bold;
  }

  .button-close {
    @apply bg-transparent hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-400 hover:text-gray-900 dark:hover:text-white;
    
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    padding: 0.125rem;
    border-radius: 0.5rem;
    font-size: .875rem;
    line-height: 1.25rem;

    .icon-close {
      width: 20px;
      height: 20px;
    }
  }
}

.footer {
  @apply border-gray-200;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>