import {getCurrentFlowName, getFlowData, resetFlowData} from "@/store/flowInterface";
import router from "@/router";

export function confirmExitFlow() {
  return window.confirm(
    "Weet u zeker dat u het maken van producten wilt afsluiten?",
  );
}
export function navigateToFlow(path: string) {
  const currentFlow = getFlowData(getCurrentFlowName());

  /* Exiting the flow besides payment pages and very first page in each flow */
  const exitNormalFlow = !['stap1', 'betalingEerste', 'betalingMaandelijkse'].includes(currentFlow.activePage);

  /* Exiting step 3 or 4 (Calculation) */
  const exitCalcFlow = ['resultaat', 'aanvrager:AanvragerKeuze'].includes(currentFlow.activePage);

  const hardReloadPath = `${window.location.origin}${path}`;

  if (exitCalcFlow) {
    if (
        window.confirm(
            "Weet u zeker dat u de flow wilt afbreken? Zo ja, dan kunt u deze op een later moment afronden via ‘Mijn berekeningen’ op de Homepage."
        )
    ) {
      resetFlowData(getCurrentFlowName());
      window.location.href = hardReloadPath;
      return;
    }
  }
  else if (exitNormalFlow) {
    if (
        window.confirm(
            "Weet u zeker dat u een nieuw product wilt toevoegen?"
        )
    ) {
      resetFlowData(getCurrentFlowName());
      window.location.href = hardReloadPath;
      return;
    }
  }

  else if (currentFlow.activePage === 'betalingEerste' || currentFlow.activePage === 'betalingMaandelijkse') {
    if (
        window.confirm(
            "Weet u zeker dat u de betaalflow wilt afbreken? Zo ja, dan kunt u deze op een later moment afronden via ‘Betaling afronden’ op de Homepage."
        )
    ) {
      resetFlowData(getCurrentFlowName());
      window.location.href = hardReloadPath;
      return;
    }
  }
  else {
    router.push({ path, replace: true, query: { t: Math.random() } });
  }
}
