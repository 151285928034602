import store, { StoreState } from "@/store";
import { FlowName, getFlowData, Question, Questionnaire } from "@/store/flowInterface";
import { showLoading } from "@/store/mainInterface";
import request, { requestMethodType } from "@/plugins/request";
import type { MutationTree } from 'vuex';

export const questionnaireInitialState: {
  questionnaire: {
    questions: Question[];
  };
} = {
  questionnaire: {
    questions: null,
  },
}

export const questionnaireInterfaceMutations: MutationTree<StoreState> = {
  setQuestionnaireQuestions(state, payload: Question[]) {
    state.questionnaire.questions = payload;
  }
}

export async function getQuestionnaireQuestions() {
  const questions = store.state.questionnaire.questions;

  if (questions) {
    return questions;
  }
  else {
    showLoading(true);
    const [response1, response2] = await Promise.all([
      request(`/api/questionnaires/1`, requestMethodType.GET, null, { headers: {}, auth: true }),
      request(`/api/questionnaires/2`, requestMethodType.GET, null, { headers: {}, auth: true }),
    ]);
    showLoading(false);

    const questionnaire1: Questionnaire = response1.data.data;
    const questionnaire2: Questionnaire = response2.data.data;

    const data = questionnaire1.questions.concat(questionnaire2.questions);
    store.commit('setQuestionnaireQuestions', data);
    return data;
  }
}


export async function getQuestionnaireResults(flow: FlowName) {
  const flowData = getFlowData(flow);

  // Check if all questions are answered
  if (flowData.answers?.every(answer => answer.answer)) {
    const questions = await getQuestionnaireQuestions();

    // Collect all results from the API questions
    const results = questions.map(question => {
      // The answerId for this question
      const answerId = flowData.answers.find(answer => answer.question === question.id).answer;

      // Return the actual text of the question and answer
      return {
        Vraag: question.question,
        Antwoord: question.answers.find(answer => answer.id === answerId).answer,
      }
    });

    // Add initial statements if applicable. Right now, the questions are hardcoded, but this could be changed to be more dynamic.
    if (flowData.thirdStatement) {
      const Vraag = 'Met dit pensioenproduct wordt beoogd een hoger rendement te behalen dan met een verzekeringsproduct. Uw uiteindelijke pensioen kan hierdoor hoger uitvallen. Aan dit mogelijk hogere rendement zijn ook risico’s verbonden. Het is derhalve van belang te bepalen wat de doelstelling is van de aankoop van dit product.';
      let Antwoord: string;

      switch (flowData.thirdStatement) {
        case 'niet-noodzakelijke':
          Antwoord = 'Dit product dient als een niet-noodzakelijke aanvulling op mijn pensioen. Het hogere rendement van dit product is echter gewenst. Tegenvallende resultaten zijn vervelend, maar ik kan deze dragen';
          break;
        case 'noodzakelijke':
          Antwoord = 'Dit product dient als een noodzakelijke aanvulling op mijn pensioen. Ik ben hiervan afhankelijk en kan een tegenvallend rendement niet dragen';
          break;
        case 'noodzakelijke-aanvulling':
          Antwoord = 'Dit product dient als een noodzakelijke aanvulling op mijn pensioen. Ik ben hiervan afhankelijk. Ik kies echter bewust voor een hoger rendement en de daarmee gepaard gaande risico’s';
          break;
        case 'niet-afhankelijk':
          Antwoord = 'Ik kies dit product vanwege de fiscale faciliteit waarvan ik gebruik wil maken. Ik ben niet afhankelijk van dit product';
          break;
      }

      results.unshift({ Vraag, Antwoord });
    }

    if (flowData.secondStatement) {
      const Vraag = 'Dit product is een zogenaamd derde-pijler pensioenproduct. De derde pijler is bedoeld om de AOW (eerste pijler) en het pensioen van de werkgever (tweede pijler) aan te vullen. Dat betekent dat dit product invloed heeft op de omvang van uw pensioen. Het is van belang dat u de gevolgen hiervan overziet.';
      let Antwoord: string;

      switch (flowData.secondStatement) {
        case 'begrepen':
          Antwoord = 'Ik heb mij verdiept in de omvang van mijn pensioen en ik overzie de gevolgen van positieve en negatieve ontwikkelingen in de belegging in dit product';
          break;
        case 'ingeschakeld':
          Antwoord = 'Ik heb een adviseur ingeschakeld welke mij inzage heeft gegeven in de omvang van mijn pensioen en mij heeft uitgelegd wat de gevolgen zijn van positieve en negatieve ontwikkelingen in de belegging in dit product';
          break;
        case 'ingezien':
          Antwoord = 'Ik ben onzeker over de omvang van mijn pensioen en/of wat de gevolgen zijn van positieve en negatieve ontwikkelingen in de beleggingen van dit product';
          break;
      }

      results.unshift({ Vraag, Antwoord });
    }

    if (flowData.firstStatement) {
      const Vraag = 'Voordat u gaat beleggen, is het van belang dat u zich goed heeft verdiept in de voorwaarden, het beleggingsbeleid en de risico’s van de betreffende belegging. Met dit product belegt u in een beleggingsfonds. De risico’s van deze beleggingen zijn omschreven in het <a class="link" target="_blank" href="https://www.trustus.nl/downloads">prospectus</a>.';
      let Antwoord: string;

      switch (flowData.firstStatement) {
        case 'begrepen':
          Antwoord = 'Ik heb het prospectus en het aanvullende prospectus gelezen en begrijp de inhoud hiervan';
          break;
        case 'ingeschakeld':
          Antwoord = 'Ik heb een adviseur ingeschakeld die mij de inhoud van het prospectus heeft uitgelegd';
          break;
        case 'ingezien':
          Antwoord = 'Ik heb het prospectus ingezien, maar begrijp de inhoud daarvan niet of niet volledig';
          break;
      }

      results.unshift({ Vraag, Antwoord });
    }

    return results;
  } else {
    return null;
  }
}