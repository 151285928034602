import { createFileStore } from "@/db/files";

const config = {
  dbName: "db",
  dbVersion: 1,
};

function createStores(db: IDBDatabase) {
  createFileStore(db);
}

export async function getStore(storeName: string, mode: IDBTransactionMode) {
  try {
    let db = await getDatabase();
    if (!db.objectStoreNames.contains(storeName)) {
      console.warn("Store not found, attempting to recreate database");
      db.close();
      await deleteDatabase();
      db = await getDatabase();
      if (!db.objectStoreNames.contains(storeName)) {
        throw new Error("Store still does not exist after reinitialization");
      }
    }
    const tx = db.transaction(storeName, mode);
    return tx.objectStore(storeName);
  } catch (error) {
    console.error("Failed to get store:", error);
    throw error;
  }
}

export async function clearStore(storeName: string) {
  const store = await getStore(storeName, "readwrite");
  const request = store.clear();

  return new Promise<void>((resolve) => {
    request.onsuccess = () => resolve();
  });
}

function getDatabase() {
  const request = window.indexedDB.open(config.dbName, config.dbVersion);

  return new Promise<IDBDatabase>((resolve, reject) => {
    request.onerror = (event: Event) => {
      console.error(
        "IndexedDB error:",
        (event.target as IDBOpenDBRequest).error,
      );
      reject((event.target as IDBOpenDBRequest).error);
    };

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result as IDBDatabase;
      createStores(db);
    };

    request.onsuccess = (event: Event) => {
      const db = (event.target as IDBOpenDBRequest).result as IDBDatabase;
      resolve(db);
    };

    request.onblocked = () => {
      console.warn("IndexedDB open request is blocked");
    };
  });
}

export function deleteDatabase() {
  const request = window.indexedDB.deleteDatabase(config.dbName);

  return new Promise<void>((resolve, reject) => {
    request.onerror = () => {
      console.error("Failed to delete database:", request.error);
      reject(request.error);
    };

    request.onsuccess = () => {
      resolve();
    };

    request.onblocked = () => {
      console.warn("Delete operation blocked due to open connections");
    };
  });
}
