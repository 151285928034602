<template>
  <div class="wrapper-3">
    <div class="wrapper-2">
      <div class="wrapper-1">
        <div v-for="(item, index) in filteredMenuItems" :key="index" class="nav-group-container">
          <div class="nav-accordion" @click="toggleAccordion(index)">
            <p class="nav-title">{{ item.name }}</p>
            <ArrowExpand 
                :direction="state.isOpen.includes(index) ? 'down' : 'up'" 
              />
          </div>

          <div :class="{ 'nav-group': index !== menuItems.length, 'closed': state.isOpen.includes(index) }">
            <div v-for="(menuItem, index) in item.items" :key="index" :class="['nav-button-container', menuItem.active ? 'active' : 'inactive']">
              <div class="flex flex-row justify-between items-center py-2 px-4" @click="() => navigateToFlow(menuItem.route)">
                <span> {{ menuItem.name }} </span> 
                <span v-if="menuItem.count" class="font-semibold"> 
                  {{ menuItem.count }} 
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="mijn-instellingen-wrapper">
          <SettingsIcon />
          <RouterLink class="mijn-instellingen-link" to="/mijn-instellingen/persoonlijke-informatie">Mijn instellingen</RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import { computed, reactive } from "vue";
import useMenuItems from "@/helpers/useMenuItems";
import { resetFlowData, getCurrentFlowName, getFlowData } from "@/store/flowInterface";
import { getCurrentUser } from "@/store/mainInterface";
import ArrowExpand from "@/assets/ArrowExpand.vue";
import SettingsIcon from "@/assets/SettingsIcon.vue";

const { menuItems } = useMenuItems();
const currentUser = getCurrentUser();

const filteredMenuItems = computed(() => {
  if (currentUser.role !== 'admin') {
    return menuItems.value;
  }
  return menuItems.value
    .filter(menuItem => menuItem.name !== "Producten toevoegen")
    .map(menuItem => ({
      ...menuItem,
      items: menuItem.items
    }));
});

const state = reactive<{
  isOpen: number[];
}>({
  isOpen: [],
});

function toggleAccordion(index: number) {
  if (state.isOpen.includes(index)) {
    state.isOpen = state.isOpen.filter(i => i !== index);
  } else {
    state.isOpen.push(index);
  }
}


function navigateToFlow(path: string) {
  const currentFlow = getFlowData(getCurrentFlowName());

  /* Exiting the flow besides payment pages and very first page in each flow */
  const exitNormalFlow = !['stap1', 'betalingEerste', 'betalingMaandelijkse'].includes(currentFlow.activePage);

  /* Exiting step 3 or 4 (Calculation) */
  const exitCalcFlow = ['resultaat', 'aanvrager:AanvragerKeuze'].includes(currentFlow.activePage);

  const hardReloadPath = `${window.location.origin}${path}`;

  if (exitCalcFlow) {
    if (
      window.confirm(
        "Weet u zeker dat u de flow wilt afbreken? Zo ja, dan kunt u deze op een later moment afronden via ‘Mijn berekeningen’ op de Homepage."
      )
    ) {
      resetFlowData(getCurrentFlowName());
      window.location.href = hardReloadPath;
      return;
    }
  }
  else if (exitNormalFlow) {
    if (
      window.confirm(
        "Weet u zeker dat u een nieuw product wilt toevoegen?"
      )
    ) {
      resetFlowData(getCurrentFlowName());
      window.location.href = hardReloadPath;
      return;
    }
  }

  else if (currentFlow.activePage === 'betalingEerste' || currentFlow.activePage === 'betalingMaandelijkse') {
    if (
      window.confirm(
        "Weet u zeker dat u de betaalflow wilt afbreken? Zo ja, dan kunt u deze op een later moment afronden via ‘Betaling afronden’ op de Homepage."
      )
    ) {
      resetFlowData(getCurrentFlowName());
      window.location.href = hardReloadPath;
      return;
    }
  }
  else {
    router.push({ path, replace: true, query: { t: Math.random() } });
  }
}



</script>

<style scoped lang="scss">
.wrapper-3 {
  @apply hidden lg:block md:w-1/5 bg-yellow-bg;
}

.wrapper-2 {
  @apply flex justify-end;
}

.wrapper-1 {
  @apply md:w-2/3;
}

.nav-group-container {
  @apply pb-5;

  .nav-accordion {
    @apply mb-5 flex justify-between h-7 cursor-pointer;

    .nav-title {
      @apply text-label-color-4 text-sm font-bold pt-1;
    }

    .transform {
      transform: rotate(180deg);
    }
  }

  .nav-group {
    @apply pb-5 border-b-[1px];

    &.closed {
      display: none;
    }

    .nav-button-container {
      @apply bg-white mb-2 border-l-2 hover:border-label-color-2 hover:text-label-color-2 cursor-pointer text-left;

      &.active {
        @apply text-label-color-2 border-label-color-2;
      }

      &.inactive {
        @apply border-label-grey-2 text-label-grey-2;
      }

    }
  }
}

.mijn-instellingen-wrapper {
  @apply flex gap-2 px-3 py-4 border-1 border-label-color-2 cursor-pointer;

  .mijn-instellingen-icon {
    @apply mr-2;
  }

  .mijn-instellingen-link {
    @apply text-label-color-2 leading-4;
  }
}
</style>