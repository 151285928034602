<template>
  <CustomLoader :isLoading="isLoading" :text="loaderText"/>

  <div class="flex flex-col min-h-screen">
    <!-- Notifications container -->
    <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-30"
    >
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <!-- Notifications, dynamically insert these into the live region when it needs to be displayed -->
        <Transition
          v-for="notification in notifications"
          :key="notification.id"
          enterActiveClass="transform ease-out duration-300 transition"
          enterFromClass="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterToClass="translate-y-0 opacity-100 sm:translate-x-0"
          leaveActiveClass="transition ease-in duration-100"
          leaveFromClass="opacity-100"
          leaveToClass="opacity-0"
        >
          <div
            v-if="showToast"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <!-- <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" /> -->
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900">
                    {{ notification?.title }}
                  </p>
                  <p class="mt-1 text-sm text-gray-500">
                    {{ notification?.message }}
                  </p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    @click="closeNotification({ id: notification?.id })"
                    class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-0"
                  >
                    <span class="text-sm">Close</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>

    <DashboardHeader />

    <!-- Main content -->
    <div
      :class="[
        customPaddings + ' lg:space-x-8 my-10 flex bg-yellow-bg ',
        {
          'flex-col items-center': !isAuthenticated || route.meta.hideLeftMenu,
        },
      ]"
    >
    <!-- Trustus nav -->
      <Navigation v-if="isAuthenticated && !route.meta.hideLeftMenu && env === 'trustus'" />
      <RouterView />
    </div>
    <CookieBanner />
    <Footer class="mt-auto" />
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import Footer from '@/components/Footer.vue';
import Navigation from '@/components/Navigation.vue';
import '@vuepic/vue-datepicker/dist/main.css';
import CookieBanner from './components/CookieBanner.vue';
import DashboardHeader from './components/DashboardHeader.vue';
import request, { requestMethodType } from "@/plugins/request";
import { getCookie } from "tiny-cookie";
import { useRoute } from 'vue-router';
import { closeNotification, getLoaded, getLoading, getLoadingText, showLoading } from './store/mainInterface';
import { currentEnv } from './plugins/variables';
import CustomLoader from './components/CustomLoader.vue';

const route = useRoute();
const store = useStore();
const env = currentEnv;
const interval = ref<number>(0);

const customPaddings = env === 'synvest' && 'pt-4 mx-4 md:pt-0 md:mx-0';

const isLoaded = computed(() => getLoaded());
const isLoading = computed(() => getLoading());
const loaderText = computed(() => getLoadingText());

const isAuthenticated = computed(() => store.getters.isAuthenticated);
const notifications = computed(() => store.getters.notifications);
const showToast = computed(() => notifications.value.length > 0);

watch(isAuthenticated, async (newValue, oldValue) => {
  if (newValue === true && oldValue === false && interval.value === 0) {
    const result = await request(`/api/getCookie?cookieName=token`, requestMethodType.GET, {})
    const token = result.data.cookie;
    if (token) {
      // Token is not null, proceed with decoding
      const tokenParts = token.split('.');
      const tokenDecoded = JSON.parse(atob(tokenParts[1]));
      const tokenExp = tokenDecoded.exp;

      const diff = tokenExp * 1000 - Date.now();

      // Set timeout to check if token is expired once
      setTimeout(() => {
        request(`/api/session-health-check`, requestMethodType.GET, {}, { headers: {}, auth: true });
      }, diff);

      // Set interval of every minute to check if token is expired
      const intervalId = setInterval(() => {
        request(`/api/session-health-check`, requestMethodType.GET, {}, { headers: {}, auth: true });
      }, 60000);

      interval.value = intervalId;
    }
  }

  if (newValue === false && oldValue === true && interval.value !== 0) {
    clearInterval(interval.value);
    interval.value = 0;
  }
});


onMounted(() => {
  document.title = env === 'trustus' ? 'Trustus' : 'Synvest';

  if (isLoaded) {
    showLoading(false);
  }

  if (isAuthenticated.value) {
    request(`/api/session-health-check`, requestMethodType.GET, {}, { headers: {}, auth: true });
  }
});
</script>

<style scoped lang="scss">
#app {
  font-family: SinkinSans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}
</style>
