import store, { StoreState } from '@/store';
import moment from 'moment';
import request, {requestMethodType} from '@/plugins/request';
import { refreshOffers } from '@/store/mainInterface';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import type { Calculation, RiskProfileType, UitkeringsTermijn } from '@/types';

// String union types
export type DutchBoolean = 'ja' | 'nee';
export type FlowName = 'bedragBeleggen' | 'lijfrenteOpbouwen' | 'lijfrenteUitkeren';
export type InvestmentFrequency = 'eenmalig' | 'maandelijks' | 'eenmalig-en-maandelijks';
export type AanvragerType = 'voor-mijzelf' | 'voor-mijzelf-en-mijn-partner' | 'voor-mijn-minderjarige-kind' | 'voor-mijn-bedrijf';
export type RiskSelectionType = 'result' | 'restart' | 'fund';
export type StatementType = 'begrepen' | 'ingeschakeld' | 'ingezien';
export type ThirdStatementType = 'niet-noodzakelijke' | 'noodzakelijke' | 'noodzakelijke-aanvulling' | 'niet-afhankelijk';
export type SituationType = 'inleggen' | 'oversluiten';
export type DurationType = 'aow' | 'manual';

export type Fund = { id: number, description: string, percentage: number };
type Payment = { paymentId: any, requestId: any };
type Twikey = { key: string; mandateId: string; url: string; };

// Questionnaire data
export type Questionnaire = {
  id: string, // '1'
  questions: Question[]
};
export type Question = {
  id: string; // '1.1'
  question: string; // 'Weet u welke risico’s u loopt als u gaat beleggen in een beleggingsfonds?'
  is_scored: boolean; // false
  answers: {
    id: string; // '1.1.1'
    answer: string; // 'Mijn inziens loop ik geen risico(s)'
  }[];
};
export type Answer = {
  question: string; // '1.1'
  answer: string; // '1.1.1'
}

// Step 1 data
export type BeleggingToevoegenData = {
  kind: InvestmentFrequency;
  initialDeposit: number | null;
  periodicDeposit: number | null;
  duration: number;
  description: string;
};
type LijfrenteToevoegenData = {
  situation: SituationType;
  initialDeposit: number | null;
  periodicDeposit: number | null;
  geboortedatum: string;
  durationType: DurationType;
  duration: number;
  start: string;
};
type LijfrenteUitkerenStep1Data = {
  duration: number;
  geboortedatum: string;
  initialDeposit: number;
  kapitaal: DutchBoolean;
  start: string;
  uitkeringsTermijnCode: UitkeringsTermijn;
};

// Resultaat page data
type ResultaatData = {
  duration: number;
  funds: Fund[];
  initialDeposit: number;
} & (
  {
    description: string;
    kind: InvestmentFrequency;
    periodicDeposit: number;
    productCode: 'SB';
  } |
  {
    durationType: DurationType;
    geboortedatum: string;
    periodicDeposit: number;
    productCode: 'SUL';
    situation: SituationType;
    start: string;
  } |
  {
    geboortedatum: string;
    kapitaal: DutchBoolean;
    productCode: 'SDIL';
    start: string;
    uitkeringsTermijnCode: UitkeringsTermijn;
  }
);

type UpdateResultaatData = {
  initialDeposit: number;
} & (
  {
    duration: number;
    kind: InvestmentFrequency;
    periodicDeposit: number;
    productCode: 'SB';
  } |
  {
    periodicDeposit: number;
    productCode: 'SUL';
  } |
  {
    productCode: 'SDIL';
    uitkeringsTermijnCode: UitkeringsTermijn;
  }
);

// These fields are only used in questionnaire pages, so could maybe be moved to a separate file later
type QuestionnaireData = {
  activeQuestion: number;
  answers: Answer[];
  firstStatement: StatementType;
  questionEleven: DutchBoolean;
  questions: {
    question: string // '1.1'
    answer: string // '1.1.1'
  }[];
  riskProfiles: RiskProfileType[];
  riskSelection: RiskSelectionType;
  score: RiskProfileType;
  scoreOne: number;
  scoreTwo: number;
  secondStatement: StatementType;
  thirdStatement: ThirdStatementType;
};
const questionnaireInitialState: QuestionnaireData = {
  activeQuestion: 0,
  answers: null,
  firstStatement: null,
  questionEleven: null,
  questions: [],
  riskProfiles: [],
  riskSelection: null,
  score: null,
  scoreOne: null,
  scoreTwo: null,
  secondStatement: null,
  thirdStatement: null,
};

// These fields are used in all flows
type BaseFlowData = QuestionnaireData & {
  activePage: string;
  calculationBasedOnProfile: boolean;
  duration: number;
  for: AanvragerType;
  offerID: number;
  funds: Fund[],
  initialDeposit: number;
  payment: Payment;
};

// These fields are unique to each flow (some appear in multiple flows, but not all three)
type BedragBeleggenData = BaseFlowData & {
  description: string;
  kind: InvestmentFrequency;
  periodicDeposit: number;
  productCode: 'SB';
  twikey: Twikey;
};
type LijfrenteOpbouwenData = BaseFlowData & {
  durationType: DurationType;
  geboortedatum: string;
  periodicDeposit: number;
  productCode: 'SUL';
  situation: SituationType;
  start: string;
  twikey: Twikey;
};
type LijfrenteUitkerenData = BaseFlowData & {
  start: string;
  geboortedatum: string;
  kapitaal: DutchBoolean;
  productCode: 'SDIL';
  uitkeringsTermijnCode: UitkeringsTermijn;
};

type FlowData = BedragBeleggenData | LijfrenteOpbouwenData | LijfrenteUitkerenData;

const baseFlowInitialState: BaseFlowData = {
  ...questionnaireInitialState,
  activePage: 'stap1',
  calculationBasedOnProfile: null,
  duration: null,
  for: null,
  funds: [],
  initialDeposit: null,
  offerID: null,
  payment: null,
};

export const flowInitialState: {
  currentFlow: FlowName;
  bedragBeleggen: BedragBeleggenData;
  lijfrenteOpbouwen: LijfrenteOpbouwenData;
  lijfrenteUitkeren: LijfrenteUitkerenData;
} = {
  currentFlow: 'bedragBeleggen',
  bedragBeleggen: {
    ...structuredClone(baseFlowInitialState),

    description: null,
    kind: null,
    periodicDeposit: null,
    productCode: 'SB',
    twikey: null,
  },
  lijfrenteOpbouwen: {
    ...structuredClone(baseFlowInitialState),

    durationType: null,
    geboortedatum: null,
    periodicDeposit: null,
    productCode: 'SUL',
    situation: null,
    start: null,
    twikey: null,
  },
  lijfrenteUitkeren: {
    ...structuredClone(baseFlowInitialState),

    geboortedatum: null,
    kapitaal: null,
    productCode: 'SDIL',
    start: null,
    uitkeringsTermijnCode: null,
  },
};

export const flowInterfaceGetters: GetterTree<StoreState, StoreState> = {
  currentFlowName: (state) => state.currentFlow,
  currentFlow: (state): FlowData => state[state.currentFlow],
  flowData: (state) => (flow: FlowName): FlowData => state[flow],
  activePage: (state) => (flow: FlowName) => state[flow].activePage,
  beleggingToevoegenData: (state): BeleggingToevoegenData => ({
    kind: state.bedragBeleggen.kind,
    initialDeposit: state.bedragBeleggen.initialDeposit,
    periodicDeposit: state.bedragBeleggen.periodicDeposit,
    duration: state.bedragBeleggen.duration,
    description: state.bedragBeleggen.description,
  }),
  lijfrenteToevoegenData: (state): LijfrenteToevoegenData => ({
    situation: state.lijfrenteOpbouwen.situation,
    initialDeposit: state.lijfrenteOpbouwen.initialDeposit,
    periodicDeposit: state.lijfrenteOpbouwen.periodicDeposit,
    geboortedatum: state.lijfrenteOpbouwen.geboortedatum,
    durationType: state.lijfrenteOpbouwen.durationType,
    duration: state.lijfrenteOpbouwen.duration,
    start: state.lijfrenteOpbouwen.start,
  }),
  lijfrenteUitkerenStep1Data: (state): LijfrenteUitkerenStep1Data => ({
    kapitaal: state.lijfrenteUitkeren.kapitaal,
    initialDeposit: state.lijfrenteUitkeren.initialDeposit,
    geboortedatum: state.lijfrenteUitkeren.geboortedatum,
    duration: state.lijfrenteUitkeren.duration,
    start: state.lijfrenteUitkeren.start,
    uitkeringsTermijnCode: state.lijfrenteUitkeren.uitkeringsTermijnCode,
  }),
  resultaatData: (state) => (flow: FlowName): ResultaatData => {
    switch (flow) {
      case 'bedragBeleggen':
        return {
          description: state[flow].description,
          duration: state[flow].duration,
          funds: state[flow].funds,
          initialDeposit: state[flow].initialDeposit,
          kind: state[flow].kind,
          periodicDeposit: state[flow].periodicDeposit,
          productCode: state[flow].productCode,
        }
      case 'lijfrenteOpbouwen':
        return {
          duration: state[flow].duration,
          durationType: state[flow].durationType,
          funds: state[flow].funds,
          geboortedatum: state[flow].geboortedatum,
          initialDeposit: state[flow].initialDeposit,
          periodicDeposit: state[flow].periodicDeposit,
          productCode: state[flow].productCode,
          situation: state[flow].situation,
          start: state[flow].start,
        }
      case 'lijfrenteUitkeren':
        return {
          duration: state[flow].duration,
          funds: state[flow].funds,
          geboortedatum: state[flow].geboortedatum,
          initialDeposit: state[flow].initialDeposit,
          kapitaal: state[flow].kapitaal,
          productCode: state[flow].productCode,
          start: state[flow].start,
          uitkeringsTermijnCode: state[flow].uitkeringsTermijnCode,
        }
      default:
        return null;
    }
  },
  questionnaireAnswers: (state) => (flow: FlowName) => state[flow].answers,
  questionnaireScore: (state) => (flow: FlowName, questionnaire: 1 | 2) => questionnaire === 1 ? state[flow].scoreOne : state[flow].scoreTwo,
  activeQuestion: (state) => (flow: FlowName) => state[flow].activeQuestion,
  questionEleven: (state) => (flow: FlowName) => state[flow].questionEleven,
  funds: (state) => (flow: FlowName) => state[flow].funds,
  riskProfile: (state) => (flow: FlowName) => state[flow].score,
  riskProfiles: (state) => (flow: FlowName) => state[flow].riskProfiles,
  riskSelection: (state) => (flow: FlowName) => state[flow].riskSelection,
  firstStatement: (state) => (flow: FlowName) => state[flow].firstStatement,
  secondStatement: (state) => (flow: FlowName) => state[flow].secondStatement,
  thirdStatement: (state) => (flow: FlowName) => state[flow].thirdStatement,
  aanvragerType: (state) => (flow: FlowName) => state[flow].for,
  payment: (state) => (flow: FlowName) => state[flow].payment,
  twikey: (state) => (flow: FlowName) => state[flow].twikey,
  offerID: (state) => (flow: FlowName) => state[flow].offerID,
};

export const flowInterfaceMutations: MutationTree<StoreState> = {
  resetFlowData: (state, payload: FlowName): void => {
    Object.assign(state[payload], structuredClone(flowInitialState[payload]));
  },
  resetFlowInterface: (state): void =>{
    Object.assign(state, structuredClone(flowInitialState))
  },
  setFlowData: (state, payload: { flow: FlowName, data: object }): void => {
    Object.assign(state[payload.flow], payload.data);
  },
  setCurrentFlowName: (state, payload: FlowName): void => {
    state.currentFlow = payload;
  },
  setActivePage: (state, payload: { flow: FlowName, page: string  }): void => {
    state[payload.flow].activePage = payload.page;
  },
  setBeleggingToevoegenData: (state, payload: BeleggingToevoegenData): void => {
    state.bedragBeleggen.kind = payload.kind;
    state.bedragBeleggen.initialDeposit = payload.initialDeposit;
    state.bedragBeleggen.periodicDeposit = payload.periodicDeposit;
    state.bedragBeleggen.duration = payload.duration;
    state.bedragBeleggen.description = payload.description;
  },
  setLijfrenteUitkerenStep1Data: (state, payload: LijfrenteUitkerenStep1Data): void => {
    state.lijfrenteUitkeren.kapitaal = payload.kapitaal;
    state.lijfrenteUitkeren.initialDeposit = payload.initialDeposit;
    state.lijfrenteUitkeren.geboortedatum = payload.geboortedatum;

    state.lijfrenteUitkeren.duration = payload.duration;
    state.lijfrenteUitkeren.start = payload.start;
    state.lijfrenteUitkeren.uitkeringsTermijnCode = payload.uitkeringsTermijnCode;
  },
  setLijfrenteToevoegenData: (state, payload: LijfrenteToevoegenData): void => {
    Object.assign(state.lijfrenteOpbouwen, payload);
  },
  setResultaatData: (state, payload: { flow: FlowName; data: ResultaatData }): void => {
    Object.assign(state[payload.flow], payload.data);
  },
  updateResultaatData: (state, payload: { flow: FlowName; data: UpdateResultaatData }): void => {
    Object.assign(state[payload.flow], payload.data);
  },
  setQuestionnaireAnswers: (state, payload: { flow: FlowName, answers: Answer[] }): void => {
    state[payload.flow].answers = payload.answers;
  },
  setQuestionnaireScore: (state, payload: { flow: FlowName, questionnaire: 1 | 2, score: number }): void => {
    if (payload.questionnaire === 1) state[payload.flow].scoreOne = payload.score;
    else if (payload.questionnaire === 2) state[payload.flow].scoreTwo = payload.score;
  },
  setQuestionEleven: (state, payload: { flow: FlowName, value: DutchBoolean }): void => {
    state[payload.flow].questionEleven = payload.value;
  },
  setFunds: (state, payload: { flow: FlowName, funds: Fund[] }) => {
    state[payload.flow].funds = payload.funds;
  },
  setRiskProfile: (state, payload: { flow: FlowName, profile: RiskProfileType }): void => {
    state[payload.flow].score = payload.profile;
  },
  setRiskProfiles: (state, payload: { flow: FlowName, profiles: RiskProfileType[] }): void => {
    state[payload.flow].riskProfiles = payload.profiles;
  },
  setRiskSelection: (state, payload: { flow: FlowName, value: RiskSelectionType }): void => {
    state[payload.flow].riskSelection = payload.value;
  },
  setCalculationBasedOnProfile: (state, payload: { flow: FlowName, value: boolean }): void => {
    state[payload.flow].calculationBasedOnProfile = payload.value;
  },
  setFirstStatement: (state, payload: { flow: FlowName, value: StatementType }): void => {
    state[payload.flow].firstStatement = payload.value;
  },
  setSecondStatement: (state, payload: { flow: FlowName, value: StatementType }): void => {
    state[payload.flow].secondStatement = payload.value;
  },
  setThirdStatement: (state, payload: { flow: FlowName, value: ThirdStatementType }): void => {
    state[payload.flow].thirdStatement = payload.value;
  },
  setAanvragerType: (state, payload: { flow: FlowName, value: AanvragerType }): void => {
    state[payload.flow].for = payload.value;
  },
  setPayment: (state, payload: { flow: FlowName, value: Payment }): void => {
    state[payload.flow].payment = payload.value;
  },
  setTwikey: (state, payload: { flow: FlowName, value: Twikey }): void => {
    state[payload.flow].twikey = payload.value;
  },
  setCurrentFlow(state, payload: { currentFlow: FlowName }) {
    state.currentFlow = payload.currentFlow;
  },
  updateFlowData(state, payload) {
    Object.assign(state[state.currentFlow], payload);
  },
  setOfferID: (state, payload: { flowName: FlowName; value: number }): void => {
    state[payload.flowName].offerID = payload.value;
  },
};

export const flowInterfaceActions: ActionTree<StoreState, StoreState> = {
  async getCalculation(context, flow: FlowName): Promise<Calculation | null> {
    const offerId = context.getters.offerID(flow);
    const isUserLoggedIn: boolean = store.getters.isAuthenticated;
    const flowData = getFlowData(flow);
  
    const createPayload = () => ({
      productCode: flowData.productCode,
      initialDeposit: flowData.initialDeposit ?? 0,
      periodicDeposit: flowData.productCode === 'SB' || flowData.productCode === 'SUL' ? flowData.periodicDeposit : 0,
      birthdate: flowData.productCode === 'SUL' || flowData.productCode === 'SDIL' ? flowData.geboortedatum : null,
      start: flowData.productCode === 'SUL' || flowData.productCode === 'SDIL' ? flowData.start : moment().startOf("month").add(1, "month").format("YYYY-MM-DD"),
      duration: flowData.duration,
      chosenRiskProfile: flowData.calculationBasedOnProfile ? flowData.score : 'NVT',
      uitkeringsTermijnCode: flowData.productCode === 'SDIL' ? flowData.uitkeringsTermijnCode : 'Geen',
      polisVoorwaardeCode: flowData.productCode === 'SDIL'
        ? flowData.kapitaal === 'ja'
          ? 'SDIL2014'
          : flowData.kapitaal === 'nee'
            ? 'SDIL'
            : null
        : null,
      isHuidigeLijfrenteOversluiten: flowData.productCode === 'SUL' && flowData.situation === 'oversluiten',
      description: flowData.productCode === 'SB' ? flowData.description : null,
      funds: flowData.funds.map(fund => ({
        id: fund.id,
        percentage: fund.percentage / 100,
      })),
    });

    let calculation: Calculation;

    showLoading(true);
    try {
      let response;

      if (!isUserLoggedIn) {
        response = await request(`/api/calculation`, requestMethodType.POST, createPayload(), { headers: {}, auth: false });
      } else if (offerId) {
        response = await request(`/api/calculation/${offerId}`, requestMethodType.GET, {}, { headers: {}, auth: true });
      } else {
        response = await request(`/api/calculation`, requestMethodType.POST, createPayload(), { headers: {}, auth: true });
      }

      calculation = response.data.data;

      if (isUserLoggedIn && !offerId) {
        await refreshOffers();
        setOfferID(flow, calculation.offerID);
      }
    } catch (e: unknown) {
      if (e.data.errors.ErrorMessage.some((message: unknown) =>
        message.includes('De minimale looptijd bedraagt 20 jaar'))) {
        throw e;
      }
      else {
        calculation = null;
      }
    }
    showLoading(false);

    return calculation;
  }
}

// Universal
export const resetFlowData = (flow: FlowName): void => store.commit('resetFlowData', flow);

export const resetFlowInterface = (): void => store.commit('resetFlowInterface');

export const getCurrentFlowName = (): FlowName => store.getters.currentFlowName;
export const setCurrentFlowName = (flow: FlowName): void => store.commit('setCurrentFlowName', flow);

export const getFlowData = (flow: FlowName): FlowData => store.getters.flowData(flow);
export const setFlowData = (flow: FlowName, data: FlowData): void => store.commit('setFlowData', { flow, data });

export const getActivePage = (flow: FlowName): string => store.getters.activePage(flow);
export const setActivePage = (flow: FlowName, page: string): void => store.commit('setActivePage', { flow, page });

// Step 1
export const getBeleggingToevoegenData = (): BeleggingToevoegenData => store.getters.beleggingToevoegenData;
export const setBeleggingToevoegenData = (data: BeleggingToevoegenData): void => store.commit('setBeleggingToevoegenData', data);
export const getLijfrenteToevoegenData = (): LijfrenteToevoegenData => store.getters.lijfrenteToevoegenData;
export const setLijfrenteToevoegenData = (data: LijfrenteToevoegenData): void => store.commit('setLijfrenteToevoegenData', data);
export const getLijfrenteUitkerenStep1Data = (): LijfrenteUitkerenStep1Data => store.getters.lijfrenteUitkerenStep1Data;
export const setLijfrenteUitkerenStep1Data = (data: LijfrenteUitkerenStep1Data): void => store.commit('setLijfrenteUitkerenStep1Data', data);

// Kennis en ervaringstoets
export const getFirstStatement = (flow: FlowName): StatementType => store.getters.firstStatement(flow);
export const setFirstStatement = (flow: FlowName, value: StatementType): void => store.commit('setFirstStatement', { flow, value });

export const getSecondStatement = (flow: FlowName): StatementType => store.getters.secondStatement(flow);
export const setSecondStatement = (flow: FlowName, value: StatementType): void => store.commit('setSecondStatement', { flow, value });

export const getThirdStatement = (flow: FlowName): ThirdStatementType => store.getters.thirdStatement(flow);
export const setThirdStatement = (flow: FlowName, value: ThirdStatementType): void => store.commit('setThirdStatement', { flow, value });

export const getQuestionnaireAnswers = (flow: FlowName): Answer[] => store.getters.questionnaireAnswers(flow);
export const setQuestionnaireAnswers = (flow: FlowName, answers: Answer[]): void => store.commit('setQuestionnaireAnswers', { flow, answers });

export const getQuestionnaireScore = (flow: FlowName, questionnnaire: 1 | 2): number => store.getters.questionnaireScore(flow, questionnnaire);
export const setQuestionnaireScore = (flow: FlowName, questionnaire: 1 | 2, score: number): void => store.commit('setQuestionnaireScore', { flow, questionnaire, score });

export const getQuestionEleven = (flow: FlowName): DutchBoolean => store.getters.questionEleven(flow);
export const setQuestionEleven = (flow: FlowName, value: DutchBoolean): void => store.commit('setQuestionEleven', { flow, value });

// Miscellaneous
export const getAanvragerType = (flow: FlowName): AanvragerType => store.getters.aanvragerType(flow);
export const setAanvragerType = (flow: FlowName, value: AanvragerType): void => store.commit('setAanvragerType', { flow, value });

export const getRiskProfiles = (flow: FlowName): RiskProfileType[] => store.getters.riskProfiles(flow);
export const setRiskProfiles = (flow: FlowName, profiles: RiskProfileType[]): void => store.commit('setRiskProfiles', { flow, profiles });

export const getRiskSelection = (flow: FlowName): RiskSelectionType => store.getters.riskSelection(flow);
export const setRiskSelection = (flow: FlowName, value: RiskSelectionType): void => store.commit('setRiskSelection', { flow, value });

export const getFunds = (flow: FlowName): Fund[] => store.getters.funds(flow);
export const setFunds = (flow: FlowName, funds: Fund[]): void => store.commit('setFunds', { flow, funds });

export const getRiskProfile = (flow: FlowName): RiskProfileType => store.getters.riskProfile(flow);
export const setRiskProfile = (flow: FlowName, profile: RiskProfileType ): void => store.commit('setRiskProfile', { flow, profile });

export const getPayment = (flow: FlowName): Payment => store.getters.payment(flow);
export const setPayment = (flow: FlowName, value: Payment): void => store.commit('setPayment', { flow, value });

export const getTwikey = (flow: FlowName): Twikey => store.getters.twikey(flow);
export const setTwikey = (flow: FlowName, value: Twikey): void => store.commit('setTwikey', { flow, value });

export const setCalculationBasedOnProfile = (flow: FlowName, value: boolean): void => store.commit('setCalculationBasedOnProfile', { flow, value });

export const getResultaatData = (flow: FlowName): ResultaatData => store.getters.resultaatData(flow);
export const setResultaatData = (flow: FlowName, data: ResultaatData): void => store.commit('setResultaatData', { flow, data });
export const updateResultaatData = (flow: FlowName, data: UpdateResultaatData): void => store.commit('updateResultaatData', { flow, data });

export const getOfferID = (flowName: FlowName): number => store.getters.offerID(flowName);
export const setOfferID = (flowName: FlowName, value: number): void => store.commit("setOfferID", { flowName, value });

export const getCalculation = async (flow: FlowName): Promise<Calculation> => await store.dispatch('getCalculation', flow);
// Temporary proxy
export const showLoading = (payload: boolean): void => store.commit('showLoading', payload);


