<template>
  <footer>
    <div class="flex flex-wrap bg-label-color-4 p-6 mt-5 w-full">
      <p
        class="mt-4 md:ml-6 lg:mt-0 ml-4 text-white hover:text-white text-xs lg:ml-12 w-full text-left lg:w-1/6"
      >
        ©Trustus {{ currentYear }}<span class="version"> - Version: {{ buildVersion }}</span>
      </p>
      <a
        href="https://www.trustus.nl/privacy-en-cookieverklaring"
        target="_blank"
        class="mt-4 md:ml-6 ml-4 lg:mt-0 text-white hover:text-white text-xs w-full text-left lg:w-1/6"
      >
        Privacy - en cookieverklaring
      </a>
      <a
        href="https://www.trustus.nl/disclaimer"
        target="_blank"
        class="mt-4 md:ml-6 ml-4 lg:mt-0 text-white hover:text-white text-xs w-full text-left lg:w-1/6"
      >
        Disclaimer
      </a>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { ref } from "vue";

const currentYear = ref(new Date().getFullYear());

const buildVersion = import.meta.env.VITE_BUILD_VERSION;
if (buildVersion)
  console.log('build version ',buildVersion)
else
  console.log('no version set')
</script>
<style scoped lang="scss">
.version {
  color: rgb(0 88 110);
}
</style>