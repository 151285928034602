import request, { requestMethodType } from '@/plugins/request';
import type { ContractenResponseModel, ContractRendementResponseModel, ContractResponseModel, ContractSummaryResponseModel, OffertesResponseModel, Relatie } from '@/types/api';

export async function getContract(id: number): Promise<ContractResponseModel> {
  const response = await request(`/api/contracts/${id}`, requestMethodType.GET, null, { headers: {}, auth: true });
  return response.data;
}

export async function getContractRendement(id: number, from: string, to: string): Promise<ContractRendementResponseModel> {
  const response = await request(`/api/contracts/${id}/rendement?van=${from}&tot=${to}`, requestMethodType.GET, null, { headers: {}, auth: true });
  return response.data;
}

export async function getContracts(): Promise<ContractenResponseModel> {
  const response = await request(`/api/contracts`, requestMethodType.GET, null, { headers: {}, auth: true });
  return response.data;
}

export async function getContractSummaries(): Promise<ContractSummaryResponseModel> {
  const response = await request(`/api/contracts/summaries`, requestMethodType.GET, null, { headers: {}, auth: true });
  return response.data;
}

export async function getOffers(): Promise<OffertesResponseModel> {
  const response = await request(`/api/offers/`, requestMethodType.GET, null, { headers: {}, auth: true });
  return response.data;
}

export async function getRelation(): Promise<Relatie> {
  const response = await request(`/api/relations/me`, requestMethodType.GET, null, { headers: {}, auth: true });
  return response.data;
}

export async function getRelations(): Promise<Relatie[]> {
  const response = await request(`/api/relations`, requestMethodType.GET, null, { headers: {}, auth: true });
  return response.data;
}
